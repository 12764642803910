import React, { ReactElement, useState, useEffect } from "react";

import Footer from "../Footer";
import NavBar from "../NavBar";
import { Articles } from "./Articles";
import CategoriesData from "./CategoriesData";
import { PreloaderContainerLayout } from "../Contract/Layout/styled";
import Preloader from "../SVG/Preloader";
import { BlogWrapper } from "./styled";

interface BlogProps {
  categoryName: string;
}

const Index = ({ categoryName }: BlogProps): ReactElement => {
  const [isPending, setIsPending] = useState(true);
  useEffect(() => {
    setIsPending(false);
  }, []);
  CategoriesData.forEach((category) => {
    if (category.categoryName === categoryName) {
      category.isActive = true;
    } else {
      category.isActive = false;
    }
  });
  return isPending ? (
    <PreloaderContainerLayout>
      <Preloader />
    </PreloaderContainerLayout>
  ) : (
    <BlogWrapper>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-12"></div>
        </div>
      </div>
      <Articles categoryName={categoryName} />
      <Footer />
    </BlogWrapper>
  );
};

export default Index;
