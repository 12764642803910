import React, { ReactElement } from "react";
import {
  ArticleAuthorInfo,
  ArticleBlock,
  ArticleCardContent,
  ArticleTitle,
  ButtonsContainer,
  IconWrapper,
  ReadMoreButton,
  ShareButton,
  ShareButtonContent,
  ShareIcon,
  ArticleCardBottom,
  ArticleCardBottomWrapper,
} from "./styled";
import { ArticleData } from "./types";
import { navigate } from "gatsby";

interface ArticleCardProps {
  article: ArticleData;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setArticleForModal: (article: ArticleData) => void;
}

const ArticleCard = ({
  article,
  setIsModalOpen,
  setArticleForModal,
}: ArticleCardProps): ReactElement => {
  return (
    <ArticleBlock
      onClick={() => {
        navigate(`/blog/${article.articleTitle}`);
      }}
    >
      <IconWrapper>
        {article.icon && (
          <img
            style={{ height: "100%" }}
            src={article.iconPreview}
            width="100%"
          />
        )}
      </IconWrapper>
      <ArticleCardBottomWrapper>
        <ArticleCardContent>
          <ArticleTitle>{article.titleContent}</ArticleTitle>
        </ArticleCardContent>
        <ArticleCardBottom>
          <ArticleAuthorInfo>{article.authorInfo}</ArticleAuthorInfo>
          <ButtonsContainer>
            <ReadMoreButton>Weiterlesen</ReadMoreButton>
            <ShareButton variant="grayBorder">
              <ShareButtonContent
                onClick={(event) => {
                  event.stopPropagation();
                  setIsModalOpen(true);
                  setArticleForModal(article);
                }}
              >
                <ShareIcon
                  preserveAspectRatio="xMinYMin meet"
                  viewBox="0 0 24 24"
                />
                Teilen
              </ShareButtonContent>
            </ShareButton>
          </ButtonsContainer>
        </ArticleCardBottom>
      </ArticleCardBottomWrapper>
    </ArticleBlock>
  );
};

export default ArticleCard;
