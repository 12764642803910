import React, { ReactElement } from "react";
import { RouteComponentProps } from "@reach/router";

import SEO from "../components/Seo";
import Index from "../components/Blog";

interface BlogProps extends RouteComponentProps {
  categoryName: string;
  articleId?: string;
}

const Blog = ({ categoryName }: BlogProps): ReactElement => {
  return (
    <div>
      <SEO title="Blog" />
      <Index categoryName={categoryName} />
    </div>
  );
};

export default Blog;
