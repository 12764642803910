import styled from "styled-components";

import { ReactComponent as Icon } from "../../../assets/icon-sun-big.svg";
import { ReactComponent as BgIcon } from "../../../assets/contract-bg.svg";

export const LogoLink = styled.a`
  position: absolute;
  top: 30px;
  left: 30px;
  @media (max-width: 992px) {
    width: 150px;
    height: 44x;
    left: 15px;
  }
`;

export const Layout = styled.div`
  display: flex;
  position: relative;
  padding-top: 135px;
  min-height: 1000px;
  @media (max-width: 992px) {
    flex-direction: column;
    max-width: 700px;
    padding-top: 124px;
    min-height: 100vh;
  }
  @media (max-width: 767px) {
    padding-bottom: 16px;
  }
`;

export const LayoutContract = styled(Layout)`
  min-height: auto;
  padding-top: 0;
`;

export const IconSun = styled(Icon)`
  width: 50%;
  max-width: 262px;
  height: auto;
  position: absolute;
  bottom: 38px;
  left: -20px;
  z-index: 1000;
  @media (max-width: 992px) {
    display: none;
  }
`;

export const ButtonsNextBackDesk = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const ButtonsNextBackMob = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

export const ContentContainer = styled.div`
  @media (max-width: 992px) {
    padding: 0;
  }
`;

export const ContentContractContainer = styled.div`
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 992px) {
    padding: 0;
  }
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
`;

export const ContentContractLeftContainer = styled(ContentContractContainer)`
  @media (min-width: 992px) {
    position: fixed;
    height: 100%;
    max-width: 475px;
    padding: 135px 0 0 0;
  }
`;

export const ContentContractRightContainer = styled(ContentContractContainer)`
  @media (min-width: 992px) {
    margin-left: 50%;
    margin-top: 135px;
  }
`;

export const LayoutContainer = styled.div`
  position: relative;
  background-color: #f6f9fa;
  min-height: 100vh;
`;

export const BgIconComponent = styled(BgIcon)`
  position: absolute;
  width: 100%;
  overflow: hidden;
  bottom: 0;
  left: 0;
  @media (max-width: 992px) {
    display: none;
  }
`;

export const PreloaderContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  height: auto;
`;

export const PreloaderContainerLayout = styled(PreloaderContainer)`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;
