const CategoriesData = [
  {
    categoryName: "all-articles",
    isActive: false,
    label: "Alle Artikel",
  },
  {
    categoryName: "about-us",
    isActive: false,
    label: "Das sind wir",
  },
  {
    categoryName: "digital-life",
    isActive: false,
    label: "Digitale Welt",
  },
  {
    categoryName: "energy",
    isActive: false,
    label: "Strom 1x1",
  },
  {
    categoryName: "sustainable-life",
    isActive: false,
    label: "Bewusst & nachhaltig",
  },
];
export default CategoriesData;
