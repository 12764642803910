import React, { ReactElement, useEffect, useState } from "react";

import {
  ArticlesContainer,
  PaginationButton,
  PaginationPage,
  PaginationEllipsis,
  PaginationBlock,
  BlogHeader,
  CarouselBlock,
} from "./styled";
import { articlesData } from "./ArticlesData";
import ArticleCard from "./ArticleCard";
import ShareModal from "./ShareModal";
import Carousel from "../Carousel";

interface ArticlesProps {
  categoryName: string;
}

const defaultArticle = {
  title: "",
  icon: null,
  label: "",
  categoryName: "",
  authorInfo: "",
  content: "",
  titleContent: "",
  articleId: "",
  description: "",
  hashtag: null,
  articleTitle: "",
  iconPreview: null,
};

export const Articles = ({ categoryName }: ArticlesProps): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [articleForModal, setArticleForModal] = useState(defaultArticle);
  const [postNumber, setPostNumber] = useState(6);
  const [popularPostsNumber, setPopularPostsNumber] = useState(3);
  const [countPages, setCountPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const getFilteredArticleData = () => {
    if (categoryName === "all-articles") {
      return articlesData;
    }
    return articlesData.filter((article) => {
      return article.categoryName === categoryName;
    });
  };
  const getPostsParam = () => {
    const filteredArticleData = getFilteredArticleData();
    let postParam = {
      postsNumber: 3,
      countPages: Math.ceil(filteredArticleData.length / 3),
      popularPostsNumber: 3,
    };
    if (window.innerWidth > 767) {
      postParam = {
        postsNumber: 4,
        countPages: Math.ceil(filteredArticleData.length / 4),
        popularPostsNumber: 2,
      };
    }
    if (window.innerWidth > 1200) {
      postParam = {
        postsNumber: 6,
        countPages: Math.ceil(filteredArticleData.length / 6),
        popularPostsNumber: 3,
      };
    }
    return postParam;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);
  const changePostsNumber = () => {
    const postParam = getPostsParam();
    setPostNumber(postParam.postsNumber);
    setCountPages(postParam.countPages);
    setPopularPostsNumber(postParam.popularPostsNumber);
    setCurrentPage(1);
  };
  const isOS = () => {
    return navigator.userAgent.match(/ipad|iphone|Android/i);
  };
  useEffect(() => {
    changePostsNumber();
  }, [categoryName]);
  useEffect(() => {
    if (!isOS()) {
      window.addEventListener("resize", changePostsNumber);
      return () => {
        window.removeEventListener("resize", changePostsNumber);
      };
    }
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <BlogHeader>Neueste Artikel</BlogHeader>
            <div>
              <ArticlesContainer>
                {getFilteredArticleData()
                  .slice(
                    postNumber * (currentPage - 1),
                    postNumber * (currentPage - 1) + postNumber
                  )
                  .map((article, index) => {
                    return (
                      <ArticleCard
                        key={index}
                        article={article}
                        setIsModalOpen={setIsModalOpen}
                        setArticleForModal={setArticleForModal}
                      />
                    );
                  })}
              </ArticlesContainer>
              {countPages > 1 && (
                <PaginationBlock
                  count={countPages}
                  defaultPage={1}
                  siblingCount={0}
                  boundaryCount={1}
                  variant="text"
                  onChange={(event, page) => setCurrentPage(page)}
                  renderItem={(params) => {
                    if (params.type === "previous") {
                      return (
                        <PaginationButton
                          buttonName="prev"
                          disabled={params.disabled}
                          onClick={params.onClick}
                        >
                          Zurück
                        </PaginationButton>
                      );
                    }
                    if (params.type === "next") {
                      return (
                        <PaginationButton
                          buttonName="next"
                          disabled={params.disabled}
                          onClick={params.onClick}
                        >
                          Weiter
                        </PaginationButton>
                      );
                    }
                    if (params.type == "end-ellipsis" || !params.page) {
                      return <PaginationEllipsis>...</PaginationEllipsis>;
                    }
                    return (
                      <PaginationPage
                        disabled={params.disabled}
                        onClick={params.onClick}
                        selected={params.selected}
                      >
                        {params.page}
                      </PaginationPage>
                    );
                  }}
                />
              )}
              <ShareModal
                pageUrl={`/blog/${categoryName}`}
                isOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                articleData={articleForModal}
              />
            </div>
          </div>
        </div>
      </div>
      <CarouselBlock>
        <Carousel isScroll={false} />
      </CarouselBlock>
    </div>
  );
};
